import { FeedScope } from '@/__generated__/graphql.ts';
import { removeFeedPosition } from '@/components/feed/use-restore-feed.ts';
import { paths } from '@/routes-utils/paths.ts';
import {
  getPersistedState,
  persistState,
} from '../../state-utils/persisted-state.ts';

const feedKey = 'home-feed-type';

let feedType: FeedScope;

export const getFeedType = () => {
  if (!feedType) {
    feedType = getPersistedState(
      feedKey,
      FeedScope,
      FeedScope.FeedFollowing,
    ) as FeedScope;
  }
  return feedType;
};

export const setFeedType = (feed: FeedScope) => {
  feedType = feed;
  persistState(feedKey, feedType);

  removeFeedPosition(paths.default);
};
